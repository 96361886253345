<template>
  <div style="">

    <van-search
        v-model="value"
        shape="round"
        background="rgba(0,0,0,0)"
        placeholder="请输入电话号码后四位"
    />

    <div class="filter">
      <div class="box input">
        <input  placeholder="请输入学校名称"  v-model="schoolName"/>
      </div>

      <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
      >
        <template #reference>
          <div class="box select">
            <span>{{ sortName }}</span>
            <van-icon name="arrow-down" color="#999" size="10"/>
          </div>
        </template>
      </van-popover>

      <div class="box button"  @click="onSearch">筛选</div>
    </div>


    <div class="list">
      <van-list
          v-model="tableLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item" v-for="(item,index) in tableData"  :key="index">
          <div class="head van-hairline--bottom">
            退款编号：{{ item.orderSn }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="null != item.afterSalesId">(售后申请)</span>
          </div>
          <div class="goods"  v-for="(product,step) in item.orderReturnItemVos ">
            <img :src="product.productImg" alt="">

            <div class="content">
              <div class="state">{{ orderStatusList[item.status] }}</div>
              <div class="name van-multi-ellipsis--l2">{{product.name}}</div>
              <div class="price">
                <span>¥</span>
                <span>{{product.price}}</span>
              </div>
              <div class="sku">数量：{{ product.quantity }}</div>
            </div>
          </div>
          <div class="goods"  v-if="item.postageProduct">
            <div class="content">
              <div class="state">{{ orderStatusList[item.status] }}</div>
              <div class="name van-multi-ellipsis--l2">邮费商品</div>
            </div>
          </div>
          <div class="line">
            <div class="line_item" style="line-height: 1.5;">{{item.school+item.grade+item.classn}}</div>
            <div class="line_item">{{ nameDesensitization(item.name) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ phoneDesensitization(item.phone) }}</div>

            <div class="line_item"><span class="key">退款原因:</span>{{ item.memo }} <span v-if="null != item.afterSalesId">(售后退款)</span></div>
            <div class="line_item"><span class="key">退款金额:</span>¥{{ item.totalPrice+item.postFee }} 含邮费：¥{{ item.postFee }}</div>
            <div class="line_item"><span class="key">申请退款时间:</span>{{ item.createTime }}</div>
            <div class="line_item"><span class="key">购买时间:</span>{{ item.orderTime }}</div>
          </div>

          <div class="bottom van-hairline--top">
            <div>
              <span class="key">审核人:</span>
              <span class="value">{{ item.updateBy }}</span>
            </div>
            <div>
              <span class="key">审核时间:</span>
              <span class="value">{{ item.updateTime }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </div>


  </div>
</template>

<script>
import Vue from "vue";
import {desensitizeName, maskPhoneNumber} from "../../../util/util";

export default {
  name: "approved",
  data() {
    return {
      finished:false,
      tableLoading: false,
      value: '',
      schoolName: '',
      sortId: 1,
      showPopover: false,
      tableData:[],
      orderStatusList:["未确认","已确认","已完成","已取消","已驳回"],
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5 // 每页显示多少条
      },
      actions: [{text: '按申请时间', value: 1}, {text: '按订单时间', value: 2}]
    }
  },
  computed: {
    sortName() {
      return this.actions.find(e => e.value === this.sortId).text;
    }
  },
  methods: {
    onSelect(item) {
      this.sortId = item.value
    },
    //分页
    getList(page, params) {
      this.tableLoading = true
      this.$http.get(this.baseMallUrl + "/h5_mall_order/queryReturnOrderReviewed",{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize,
          deptId:Vue.prototype.deptId,
          applicationTimeSort:this.sortId === 1,
          orderTimeSort:this.sortId === 2
        }, params)},{
        emulateJSON:true
      }).then(response => {
        if(page.currentPage == 1){
          this.tableData = response.data.records
        }else{
          this.tableData = this.tableData.concat(response.data.records)
        }

        this.page.total = response.data.total
        if(this.tableData.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    onLoad() {
      this.getList(this.page,{phone:this.value,school:this.schoolName})
    },
    onSearch() {
      this.page= {
          total: 0, // 总页数
          currentPage: 1, // 当前页数
          pageSize: 5 // 每页显示多少条
      };
      this.getList(this.page,{phone:this.value,school:this.schoolName})
    },
    nameDesensitization(name){
      return desensitizeName(name);
    },
    phoneDesensitization(phone){
      if(phone){
        return maskPhoneNumber(phone)
      }else{
        return ''
      }

    }
  }
}
</script>

<style scoped lang="less">
::v-deep .van-search__content {
  background-color: #fff;
}

::v-deep .van-field__control {
  font-size: 12px;
}

.van-search {
  padding: 10px 18px 0;
}

.filter {
  display: flex;
  align-items: center;
  padding: 10px 18px 0;
  justify-content: space-between;

  .box {
    background: #FFFFFF;
    border-radius: 17px;
    border-radius: 1000px;
  }

  .input {
    width: 159px;
    height: 33px;

    input {
      height: 33px;
      padding: 0 14px;
      width: 159px;
      border: none;
      border-radius: 1000px;
      font-size: 12px;
    }
  }

  .select {
    width: 97px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: #333;
      margin-right: 6px;
    }
  }

  .button {
    width: 70px;
    height: 33px;
    background: #D61619;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 33px;
    text-align: center;
  }
}


.list {
  padding-bottom: 1px;

  .item {
    margin: 10px 18px;
    background: #FFFFFF;
    border-radius: 8px;

    .head {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;

      padding: 0 13px;

      &::after {
        border-color: #eee;
      }
    }

    .goods {
      padding: 0 14px;
      display: flex;
      align-items: center;
      padding-top: 10px;

      img {
        width: 81px;
        height: 68px;
        border-radius: 4px;
        min-width: 81px;
        margin-right: 13px;
      }

      .content {
        width: 0;
        flex: 1;
        position: relative;

        .state {
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FF0000;
          position: absolute;
          right: 0;
          top: 0;
          line-height: 1.1;
        }

        .name {
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 12px;
          width: 150px;
        }

        .price {
          color: #D53A26;
          font-size: 10px;
          font-family: Source Han Sans CN;

          span {
            font-weight: bold;
          }

          span:last-child {
            font-size: 12px;
            margin-left: 2px;
          }
        }

        .sku {
          background: #EEEEEE;
          border-radius: 2px;
          padding: 3px 5px;
          font-size: 10px;
          color: #666666;
          line-height: 11px;
          display: inline-block;
        }
      }
    }

    .line {
      padding: 0 14px;

      .line_item {
        margin-top: 10px;
        line-height: 10px;
        font-size: 10px;
        color: #999;

        .key {
          color: #212121;
          font-weight: 400;
          margin-right: 3px;
        }
      }
    }


    .bottom {
      display: flex;
      align-items: center;
      padding: 7px 14px;
      margin-top: 14px;

      > div {
        margin-right: 35px;
      }

      .key {
        font-size: 10px;
        color: #212121;
        margin-right: 4px;
      }

      .value {
        font-size: 10px;
        color: #f00;
      }
    }
  }
}
</style>
