<template>
    <div class="page-gray">
        <van-tabs
            v-model="active"
            sticky
            swipeable
            :line-width="15"
            :line-height="4">
            <van-tab title="待审核" name="0">
                <to-aodit />
            </van-tab>
            <van-tab title="已审核" name="1">
                <approved />
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import ToAodit from "@/views/refund/auditListComponent/toAodit";
import Approved from "@/views/refund/auditListComponent/approved";
export default {
    name: "audit",
    components: {Approved, ToAodit},
    data() {
        return {
            active: '0'
        }
    }
}
</script>

<style scoped lang="less">

</style>
