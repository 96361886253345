<template>
  <div>
    <div class="filter_info">
      <div class="left">
        当前共<span>{{ page.total }}</span>条待审核退款记录
      </div>
      <!--            <div class="right">-->
      <!--                共计<span>888</span>元-->
      <!--            </div>-->
    </div>

    <van-search
        v-model="value"
        shape="round"
        background="rgba(0,0,0,0)"
        placeholder="请输入电话号码后四位"
        @search="onSearch"
    />


    <div class="list">
      <van-list
          v-model="tableLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item" v-for="(item,index) in tableData"  :key="index">
          <div class="head van-hairline--bottom">
            退款编号：{{ item.orderSn }}&nbsp;&nbsp;&nbsp;&nbsp;<span v-if="null != item.afterSalesId">(售后申请)</span>
          </div>
          <div class="goods" v-for="(product,step) in item.orderReturnItemVos " :key="step">
            <img :src="product.productImg" alt="">

            <div class="content">
              <div class="name van-multi-ellipsis--l2">{{product.name}}</div>
              <div class="price">
                <span>¥</span>
                <span>{{product.price}}</span>
              </div>
              <div class="sku">数量：{{ product.quantity }}</div>
            </div>
          </div>

          <div class="goods" v-if="item.postageProduct">
            <div class="content">
              <div class="name van-multi-ellipsis--l2">邮费商品</div>
            </div>
          </div>

          <div class="line">
            <div class="line_item" style="line-height: 1.5;">{{item.school+item.grade+item.classn}}</div>
            <div class="line_item">{{ nameDesensitization(item.name) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ phoneDesensitization(item.phone) }}</div>

            <div class="line_item"><span class="key">退款原因:</span>{{ item.memo }} <span v-if="null != item.afterSalesId">(售后退款)</span></div>
            <div class="line_item"><span class="key">退款金额:</span>¥{{ item.totalPrice }}</div>
            <div class="line_item"><span class="key">退款邮费:</span>¥{{ item.postFee }}</div>
            <div class="line_item"><span class="key">申请退款时间:</span>{{ item.createTime }}</div>
            <div class="line_item"><span class="key">申请人:</span>{{ item.createBy }}</div>
            <div class="line_item"><span class="key">申请类型:</span>{{ item.userapply==0?'后台申请':"用户申请" }}</div>
          </div>

          <div class="bottom">
            <van-button type="info" plain round size="mini" @click="rejectRefund(item)">
              <span style="color:#212121">驳回退款</span>
            </van-button>
            <van-button type="primary" plain color="#D61619" round size="mini" @click="agree(item.id)">
              同意退款
            </van-button>
          </div>
        </div>
      </van-list>

    </div>

    <van-action-sheet v-model="showReason" title="驳回原因" close-icon="close">
      <van-radio-group v-model="reason_stack">
        <van-cell-group>
          <van-cell v-for="item in reasonList" :title="item.text" clickable @click="reason_stack = item.text">
            <template #title v-if="item.value === 6">
              <input type="text" class="other" v-model="item.text" placeholder="点击输入其他理由">
            </template>
            <template #right-icon>
              <van-radio :name="item.text" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round @click=" reject()">提交</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import {desensitizeName, maskPhoneNumber} from "../../../util/util";

export default {
  name: "toAodit",
  data() {
    return {
      value: '',
      reason_stack: '',
      reasonId: '',
      showReason: false,
      tableLoading:false,
      finished:false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      tableData:[],
      thisData:{},
      reasonList: [{
        text: '质量无问题',
        value: 1
      }, {
        text: '已经拆封了',
        value: 2
      }, {
        text: '3',
        value: 3
      }, {
        text: '4',
        value: 4
      }, {
        text: '5',
        value: 5
      }, {
        text: '其他',
        value: 6
      }],

    }
  },

  methods: {
    //分页
    getList(page, params) {
      this.tableLoading = true
      this.$http.get(this.baseMallUrl + "/h5_mall_order/queryReturnOrderUnaudited",{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize,
          deptId:Vue.prototype.deptId,
        }, params)},{
        emulateJSON:true
      }).then(response => {
        if(page.currentPage == 1){
          this.tableData = response.data.records
        }else{
          this.tableData = this.tableData.concat(response.data.records)
        }

        this.page.total = response.data.total
        if(this.tableData.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    onLoad() {
      this.getList(this.page,{phone:this.value})
    },
    agree(returnId) {
      this.$dialog.confirm({
        title: '标题',
        message: '是否同意该退款申请',
      }).then(() => {
        this.$http.post(this.baseMallUrl + `/h5_mall_order/agreeToRefund/${returnId}`, {}, {emulateJSON: true}).then(() => {
          this.$toast.success('已同意')
          this.tableData = [];
          this.onLoad();
        })
      }).catch(() => {
        // on cancel
      });
    },
    //驳回退款
    rejectRefund(item){
      this.thisData = item;
      this.showReason = true
    },
    reject() {
      if (!this.reason_stack) {
        this.$toast('请选择理由');
        return;
      }

      if (this.reason_stack === '其他' && this.reasonList[this.reasonList.length - 1].text === '其他') {
        this.$toast('请输入其他理由');
        return;
      }
      this.showReason = false;
      this.$http.post(this.baseMallUrl + `/h5_mall_order/rejectRefund/${this.thisData.id}`, {reason:this.reason_stack}, {emulateJSON: true}).then(() => {
        this.$toast.success('成功驳回')
        this.tableData = [];
        this.onLoad();
      })
    },
    onSearch(val) {
      this.page= {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5 // 每页显示多少条
      };
      this.getList(this.page,{phone:val})
    },
    nameDesensitization(name){
      return desensitizeName(name);
    },
    phoneDesensitization(phone){
      if(phone){
        return maskPhoneNumber(phone)
      }else{
        return ''
      }

    }
  }
}
</script>

<style scoped lang="less">
::v-deep .van-search__content {
  background-color: #fff;
}

::v-deep .van-field__control {
  font-size: 12px;
}

.van-search {
  padding: 10px 18px 0;
}

.filter_info {
  font-size: 10px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px 0;

  span {
    color: #D61619;
    margin: 0 3px;
  }
}

.list {
  padding-bottom: 1px;

  .item {
    margin: 10px 18px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 0 14px;

    .head {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 30px;

      padding: 0 13px;

      &::after {
        border-color: #eee;
      }
    }

    .goods {
      display: flex;
      align-items: center;
      padding-top: 10px;

      img {
        width: 81px;
        height: 68px;
        border-radius: 4px;
        min-width: 81px;
        margin-right: 13px;
      }

      .content {
        width: 0;
        flex: 1;

        .name {
          font-size: 10px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 12px;
        }

        .price {
          color: #D53A26;
          font-size: 10px;
          font-family: Source Han Sans CN;

          span {
            font-weight: bold;
          }

          span:last-child {
            font-size: 12px;
            margin-left: 2px;
          }
        }

        .sku {
          background: #EEEEEE;
          border-radius: 2px;
          padding: 3px 5px;
          font-size: 10px;
          color: #666666;
          line-height: 11px;
          display: inline-block;
        }
      }
    }

    .line {
      .line_item {
        margin-top: 10px;
        line-height: 10px;
        font-size: 10px;
        color: #999;

        .key {
          color: #212121;
          font-weight: 400;
          margin-right: 3px;
        }
      }
    }


    .bottom {
      text-align: right;
      padding: 8px 0 12px;

      .van-button {
        height: 24px;
        padding: 0 13px;
        margin-left: 13px;
      }
    }
  }
}

.other {
  width: 80%;
  border: 1px solid #f4f4f4;
  height: 30px;
  padding: 0 10px;
  border-radius: 4px;
}
</style>
